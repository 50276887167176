<template>
  <div>
    <div class="block banner bg pwaf">
      <div class="container">
        <div class="box">
          <h1>{{$t('productWaf')}}</h1>
          <p>{{$t('product122')}}</p>
          <p class="mobile des">
            {{$t('product123')}}
            </p>
          <el-button type="primary">{{$t('consultingBusiness')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block block1 pc">
      <div class="container">
        <el-row>
          <el-col :span="17">
            <h2>{{$t('product124')}}</h2>
            <p>
             <span v-html="$t('product125')"></span><br/>
             <span v-html="$t('product126')"></span><br/>
             <span v-html="$t('product127')"></span><br/>
             <span v-html="$t('product128')"></span><br/>
             <span v-html="$t('product129')"></span>
            </p>
          </el-col>
          <el-col :span="7">
            <OtherList hideRoute="productwaf" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('product7')}}</h2>
        <ul class="list1">
          <li class="icon13">
            <h5>{{$t('product130')}}</h5>
            <p>{{$t('product131')}}</p> 
          </li>
          <li class="icon50">
            <h5>{{$t('product132')}}</h5>
            <p>{{$t('product133')}}</p> 
          </li>
          <li class="icon51">
            <h5>{{$t('product134')}}</h5>
            <p>{{$t('product135')}}</p>  
          </li>
          <li class="icon38">
            <h5>{{$t('product136')}}</h5>
            <p>{{$t('product137')}}</p>  
          </li>
          <li class="icon26">
            <h5>{{$t('product138')}}</h5>
            <p>{{$t('product139')}}</p>  
          </li>
          <li class="icon55">
            <h5>{{$t('product140')}}</h5>
            <p>{{$t('product141')}}</p>  
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image10.svg" /></div>
            <div class="cont">
              <h4>{{$t('product142')}}</h4>
              <p>{{$t('product143')}}<br/>
              •	{{$t('product144')}}<br/>
              •	{{$t('product145')}}<br/>
              •	{{$t('product146')}}<br/>
              •	{{$t('product147')}}<br/>
              •	{{$t('product148')}}<br/>
              •	{{$t('product149')}}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image6.svg" /></div>
            <div class="cont">
              <h4>{{$t('product150')}}</h4>
              <p>{{$t('product151')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image11.svg" /></div>
            <div class="cont">
              <h4>{{$t('product152')}}</h4>
              <p>{{$t('product153')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image12.svg" /></div>
            <div class="cont">
              <h4>{{$t('product154')}}</h4>
              <p>{{$t('product155')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image13.svg" /></div>
            <div class="cont">
              <h4>{{$t('product44')}}</h4>
              <p>{{$t('product156')}}</p>
            </div>
          </li>
        </ul>
        <hr/>
        <ApplyBox />
      </div>
    </div>
  </div>
</template>
<script>
  import OtherList from '@/components/OtherList.vue'
  import ApplyBox from '@/components/ApplyBox.vue'
export default {
  name: "ProductWaf",
  components: {
    OtherList,
    ApplyBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('productWaf')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }

  .list1 {
    li {
      width: 33.33%;
    }
  }
</style>
